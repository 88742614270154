import React from "react";
import "./Header.css";
import young from "../../assets/imgs/header/young.svg";
import child from "../../assets/imgs/header/chiled.svg";
import teen from "../../assets/imgs/header/teen.svg";
import {AiFillCaretRight,AiFillCaretLeft} from 'react-icons/ai'
import { Link } from "react-router-dom";
export const Header = () => {


  
  const categoriesOwn = [
    {  head: "Packages", link: "./Packages" ,active:"active",icon:young,paragraph:"Buy your Package",spects:""},
    {  head: "Items", link: "./Items" ,active:"" ,icon:child,paragraph:"Ease to reach products",spects:"You  have to own  package to  to visit items page"},
    {  head: "SOON", link: null ,active:"" ,icon:teen,paragraph:""},
  ];


  
  

  return (
    <div className="Header">

       <div className="d-flex  justify-content-center align-items-center w-100 h-100">
        <div
          id="HeaderCarousel"
          className="carousel-fade carousel w-100 h-100"
          data-bs-ride="carousel"
          data-bs-pause="false"
          data-bs-interval='8000'
          data-bs-transition='8000'  
        >
          <div className="carousel-indicators justify-content-start">




          <button type="button" data-bs-target="#HeaderCarousel" id="points" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button"  data-bs-target="#HeaderCarousel" id="points" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#HeaderCarousel" id="points" data-bs-slide-to="2" aria-label="Slide 3"></button>
   



          </div>

          <div className="carousel-inner   h-100 " >



           {categoriesOwn?categoriesOwn.map((el,i)=>{
            return <div  key={i} className={`carousel-item ms-md-5 ms-0  h-75 w-100 w-md-75 ${el.active} `}>
            <div className="w-75 mx-auto flex-wrap d-flex justify-content-around align-items-center flex-row-reverse h-100">
              <div className="img-header col-7 col-md-6">
                <img
                  src={el.icon}
                  alt=""
                  width="80%"
                  height="auto"
                />
              </div>
              <div className="animated bounce infinite contect-header ps-md-5 ps-0 col-12 text-center text-md-start col-md-6">
                <h2> {el.head}</h2>
                <h3>{el.paragraph}</h3>
                <p>{el.spects}</p>
                <Link to={el.link}>
                <button  className="btn btn-transparent text-capitalize text-center purple-button">
                  More 
                </button>
                </Link>
              </div>
              </div>
            </div>
           }):""}

  

</div>
  
          
            <button className="carousel-control-prev" type="button" data-bs-target="#HeaderCarousel" data-bs-slide="prev">
          <span>
          <AiFillCaretLeft />
          </span>

  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#HeaderCarousel" data-bs-slide="next">

<span>
<AiFillCaretRight />  
        </span>
  </button>
        </div>
      </div>
    </div>
  );
};
