import { createSlice } from '@reduxjs/toolkit';



const intialState = [

]
const isSameItem = (a, b) => a.id === b.id && a.size === b.size;

export const cartSlice = createSlice({
    name:"cart-products",
    initialState: intialState,
    reducers:{
        AddToCart: (state,action) =>{
                const extistingIndex = state.findIndex(item => isSameItem(item, action.payload));

                if (extistingIndex === -1) {
                    state.push(action.payload);
                  }
                  // replace existing items
                  else {
                    state[extistingIndex].count = action.payload.count + state[extistingIndex].count ;
                    state[extistingIndex].totalPrice = (action.payload.count * state[extistingIndex].unitePrice) + state[extistingIndex].totalPrice ;
                  }
        },
        DeleteFromCart: (state,action) =>{
          return   state.toSpliced(action.payload,1)
        },
        ResetCart: (state,action) =>{
          return state = [];
        },
    }
})

export const {AddToCart , DeleteFromCart ,ResetCart} = cartSlice.actions;

export default cartSlice.reducer;