import './TagS.css'
    export const TagS = ({type}) => {
        if (type === 'pending'){
            return (
            <span className={`badge cutomeTag rounded-pill ${type}`}>
                {type}
            </span>)
        } else if (type === 'paied'){
            return (
                <span className={`badge cutomeTag rounded-pill  ${type}`}>
                {type}
                </span>)
        } else if (type === 'picked'){
            return (
                <span className={`badge cutomeTag rounded-pill  ${type}`}>
                {type}
                </span>)
        } else if (type === 'confirmed'){
            return (
                <span className={`badge cutomeTag rounded-pill  ${type}`}>
                {type}
                </span>)
        }
    }