/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Login.css";
import { loginFunction } from "../../functions/firebase/authentfb";
import { useNavigate } from "react-router-dom";
import  {vlogologin} from '../../assets/imgs/logos/vlogo.js';
import { hlogologin } from "../../assets/imgs/logos/hlogo";

export const Login = () => {


  const [email, setEmail] = useState("");
  const [messageValid, setmessageValid] = useState("");
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
useEffect(() => {

    sessionStorage.clear();
    if(userData){
      sessionStorage.setItem('auth', JSON.stringify(userData));
      navigate('/');
    }
  
 
}, [ messageValid]);



  const handelSubmit = (e) => {
    e.preventDefault();
    loginFunction(email + "@school.sys", email.slice(-6),setmessageValid,setUserData);
    
  };


  return (
    <div className="login d-flex justify-content-center align-items-center flex-wrap">

        <div className="col-12 d-none d-md-flex col-md-6  d-flex justify-content-center align-items-center bg-image">
          <div className="w-50">
          {vlogologin}
          </div>
          
        </div>
        <form
          onSubmit={(e) => {
            handelSubmit(e);
          }}
          className="d-flex col-12  col-md-6 login-form flex-column justify-content-center align-items-center"
        >
            <div className="w-50 h-25">
            <h3 className="welcome-head">welcome to 👋</h3>
          {hlogologin}
          </div>
          <div  className="w-100 pt-5">



         
          <div className="w-100 mb-3">

            <p className="welcome-parag">Welcome back! Please enter your details.</p>
          </div>
          <div className="form-floating mb-3 w-100">
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              autoComplete="false"
              type="number"
              className={`form-control ${messageValid}`}
              id="floatingInput validation1"
              placeholder="000000000000"
            />
            <label htmlFor="floatingInput validation1">Id Number</label>
            <div className="valid-feedback">
      Looks good!
    </div>
            <div className="invalid-feedback">
      id number is not correct
    </div>
          </div>

          <button type="submit" className="btn btn-primary btn-custom mt-3 w-100  py-2">
            Submit
          </button>
          </div>
        </form>

    </div>
  );
};
