import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
    allPatment:[],
    lastCheckOut:0,
    name:"",
    wishlist:[],
    show:'',
    ownPackage:false,
    email:"",
    grade:"",
    id:"",
    gender:"",
}

export const UserSlice = createSlice({
    name:'userAuth',
    initialState:initialUserState,
    reducers:{
        LogInFun:(state, action) => {
          return  state = action.payload
        },
        LogOut:(state,action)=>{
            return state = initialUserState;
        }
    }
})

// Action creators are generated for each case reducer function
export const { LogInFun, LogOut } = UserSlice.actions

export default UserSlice.reducer