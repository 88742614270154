import React, { useState } from 'react'
import './SizeAndCount.css'
import { toast } from 'react-toastify';



export  const  setMaxQuantity = (selectedSize,count,setCount,quantity) =>{
    if(!selectedSize){setCount(count)} else{
    return quantity[`${selectedSize}`];
    }
  }


export  const handelCount = (proces, Prevcount, min, max,selectedSize,setCount,setstatus) => {
    if(selectedSize){
      if (proces === "minus" && Prevcount <= min) {
        setCount(Prevcount);
      } else if (proces === "plus" && Prevcount >= max) {
        setstatus('request')
        toast.info('There are no more items available so your request be created');
        
        setCount(++Prevcount);
      } else {
        if (proces === "minus") {
          setCount(--Prevcount);
        } else if (proces === "plus") {
          setCount(++Prevcount);

          if(max === 0){
            setstatus('request')
            toast.info('There are no more items available so your request be created');
          }
          
          
        }
      }

    };
    
  };

  export  const handelSelectionsSize = (e,setSelectedSize,setCount) =>{
    setSelectedSize(e);
    setCount(0)
  }

