import React, { useEffect, useRef, useState } from 'react'
import { getSpecificIdProduct } from '../../../functions/firebase/getProductsData'
import { AiFillBook, AiOutlineGift, AiOutlineLeftCircle } from 'react-icons/ai'
import placeholderImg  from "../../../assets/imgs/placeholder.jpg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { toast } from 'react-toastify';
export const ProductCardPack = ({item,bundelId,li,handelSelectSize,Packorder,setPackOrder,type}) => {
    const {name,size  ,id} = item
    const [quanitty,setQuantity] = useState()
    const [images,setimages] = useState([])
    const [sizeGuid,setsizeGuid] = useState('')
   const [index,setIndex] = useState(0)

    useEffect(() => {
        getSpecificIdProduct('products',id).then(({quantity,sizeGuide,images}) =>{
          console.log(sizeGuide)
          setQuantity(quantity)
          setsizeGuid(sizeGuide)
          setimages(images)
        });
    }, [id])
    const handelIndex = () =>{
        if( index === 3 ) {setIndex(0)}
        else{setIndex(index + 1)}
    }



const handelSize = (size,count,index) =>{
  
if(count === 0){

  if(type === 'package'){
    if(Packorder.products){
    //Packorder.products.filter(el => el.id === id && el.bundelId === bundelId)[0].size = size

    Packorder.products[index].size = size
    Packorder.products[index].status = 'request'

    //Packorder.products.filter(el => el.id === id && el.bundelId === bundelId)[0].status = 'request'
    setPackOrder(Packorder)
    toast.info('There are no more items available so your request be created');

    }
} else if (type === 'bundel'){
    if(Packorder.products){
      Packorder.products.filter(el => el.id === id)[0].size = size
      
      Packorder.products.filter(el => el.id === id)[0].status = 'request' 
    setPackOrder(Packorder)
    toast.info('There are no more items available so your request be created');
    }
  
}


}
else if (count !== 0){

  if(type === 'package'){
      if(Packorder.products){
      //Packorder.products.filter(el => el.id === id && el.bundelId === bundelId)[0].size = size
      Packorder.products[index].size = size
     
      setPackOrder(Packorder)
      }
  } else if (type === 'bundel'){
      if(Packorder.products){
        Packorder.products.filter(el => el.id === id)[0].size = size
      setPackOrder(Packorder)
      }
    
  }

}















}
    let slider = useRef();
    const settings = {
      infinite: true,
      speed: 500,
      arrows:false,
      slidesToShow: 1,
      slidesToScroll: 1,
      slickNext:handelIndex
    };
  const  next = () =>  {
      slider.slickNext();
    }
  const  nextsize = () =>  {
      slider.slickGoTo(1);
    }
  return (
    <div className=" card-pro my-2 border-0 w-100 shadow-sm rounded-3  my-2" key={li}>
      {item.gift?
      
      <div className="gift-btn px-1 py-2 rounded" ><span className='fs-6 me-2'>Gift</span><AiOutlineGift /></div>
      :""}
    
    <button className="next-btn btn m-0 p-0  border-0" onClick={next}> <AiOutlineLeftCircle /></button>
    <button className="next-btn  m-0 p-0 btn border-0 size-btn " disabled={!sizeGuid} onClick={()=> sizeGuid?nextsize():""}> <AiFillBook /></button>
        <Slider {...settings}  ref={c => (slider = c)}>
  <div className="image-container"    key={1}>
        <img src={images?images[0]:placeholderImg}  style={{objectFit:"fill"}}  width={'100%'} alt={name} className='iamge'  />
    </div>
  
  <div className="image-container"    key={2}>
  <img src={sizeGuid?sizeGuid:placeholderImg} style={{objectFit:"fill"}}  width={'100%'} alt={name} className='iamge'  />
</div>


  <div className="image-container"    key={3}>
  <img src={images?images[0]:placeholderImg} style={{objectFit:"scale-down"}} width={'100%'} alt={name} className='iamge'  />
    </div>
          </Slider>
    <div className='title-select px-2'>
        {name}
    </div>

    <div className="  p-2">
    <span className='size-select'>select size:</span>


       

    <div className="d-flex flex-wrap justify-content-between">
    {quanitty
      ?Object.keys(quanitty).filter(el => el !== 'none').map((el, i) => {
          return (
            <div
              key={i}
              className="form-check col-3 m-2 text-start custom-radio"
            >
              <input
                className="form-check-input text-center  "
                type="radio"
                name={bundelId + '&' + id + '&' + li}
                id={id + i + li}
                value={el}
                
                onChange={(e) => {
                  handelSize(e.target.value,quanitty[el],li)
                }}
              />
              <label
                className=" form-check-label d-flex flex-wrap justify-content-center align-items-center"
                htmlFor={id + i + li}
              >
                <span className="d-block">{el}</span>
          </label>
            </div>
          );
        })
      :""}
  </div>
    </div>
</div>
  )
}
