import React from 'react'
import { Header } from '../../sections/Header/Header'

export const LandingPage = () => {
  return (
    <div className='App light'>
      <Header />       
    </div>
  )
}
