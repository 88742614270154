import React, { useEffect, useState } from "react";
import "./SingleProductPage.css";
import placeholderImg from "../../assets/imgs/placeholder.jpg"
import { useParams } from "react-router";
import {
  handelCount,
  handelSelectionsSize,
  setMaxQuantity,
} from "../../Components/SizeAndCount/SizeAndCount";
import { useDispatch } from "react-redux";
import { AddToCart } from "../../functions/store/slices/cartSlice";
import { Breadcrumbs } from "../../Components/Breadcrumbs/Breadcrumbs";
import { getSpecificIdData } from "../../functions/firebase/getProductsData";
import Slider from "react-slick";
export const SingleProductPage = () => {
  const [count, setCount] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const param = useParams();
  const [product, setProduct] = useState({});

  useEffect(() => {
    getSpecificIdData("products", param.id).then((data) => setProduct(data));
  }, [param.id]);

  const { id, quantity, unitePrice, name, images, category, sizeGuide } =
    product;

  const [status, setstatus] = useState("pending");
  const dispatch = useDispatch();

  const HandelOrderButton = () => {
    if (count > 0) {
      dispatch(
        AddToCart({
          name,
          unitePrice,
          totalPrice: unitePrice * count,
          id,
          image: images ? images[0] : "",
          type: "item",
          category,
          status:status,
          size: selectedSize,
          count,
          productcount:
            setMaxQuantity(selectedSize, count, setCount, quantity) - count,
        })
      );
      setSelectedSize(null);
      setCount(0);
    }
  };
  const settings = {
    customPaging: function (i) {
      return (
        <p>
          <img src={`${images[i]}`} alt="" width={"100%"} />
        </p>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-page">
      <div className="product-page-header py-5">
        <div className="container">
          <Breadcrumbs />
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-wrap">
          {/**  image-gallery */}
          <div className="col-12 col-md-6 ">
            <Slider {...settings}>
              {images?.map((imageSrc, indexOfImage) => {
                return (
                  <div key={indexOfImage}>
                    <img src={imageSrc || placeholderImg} width={"100%"} alt={indexOfImage} />
                  </div>
                );
              })}
            </Slider>
 
          </div>
          {/** product-info */}
          <div className="col-12 col-md-6 product-details ps-0 ps-md-3">
            <h1 className="product-title">{name}</h1>
    
            <p className="product-catedory">{category}</p>

            <div className="product-controls">
              <div className="size-center my-3">
                <div className="sizecenter-header d-flex justify-content-between align-items-center">
                  <span className="product-singlePage-head ">
                    Select size (Inches)
                  </span>
                  <span
                    className="product-singlePage-head text-primary  px-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    size guide
                  </span>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5 "
                            id="exampleModalLabel"
                          >
                            Size Guide
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body ">
                          <img src={sizeGuide || placeholderImg} width={"90%"} alt="size guide" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="size-controls my-3">
                  <div className="d-flex flex-wrap justify-content-between">
                    {quantity
                      ? Object.keys(quantity)
                          .filter((els) => els !== "none")
                          .map((el, i) => {
                            return (
                              <div
                                key={i}
                                className="form-check m-2 text-center custom-radio"
                              >
                                <input
                                  className="form-check-input text-center "
                                  type="radio"
                                  name={id}
                                  id={el + i}
                                  value={el}
                                  checked={el === selectedSize}
                                  onChange={(e) => {
                                    handelSelectionsSize(
                                      e.target.value,
                                      setSelectedSize,
                                      setCount,
                                      setstatus
                                    );
                                  }}
                                />
                                <label
                                  className=" form-check-label d-flex flex-wrap justify-content-center align-items-center"
                                  htmlFor={el + i}
                                >
                                  <span className=" d-block ">{el}</span>
                                </label>
                              </div>
                            );
                          })
                      : ""}
                  </div>
                </div>
                <div className="count-center d-flex justify-content-start align-items-center mt-5">
                  <div className="count-center-quantity col-4">
                    <div className="header-count-center mb-2">Quantity</div>
                    <div className="count-center-counter d-flex  align-items-center">
                      <button
                        disabled={!selectedSize || count === 0}
                        onClick={() => {
                          handelCount(
                            "minus",
                            count,
                            0,
                            setMaxQuantity(
                              selectedSize,
                              count,
                              setCount,
                              quantity
                            ),
                            selectedSize,
                            setCount,
                            setstatus
                          ); //handelCount = (proces, Prevcount, min, max)
                        }}
                        className="btn btn-light button-count  bg-opacity-25 border border-2"
                      >
                        -
                      </button>
                      <span className="counter bg-light bg-opacity-25 ">
                        {count}
                      </span>
                      <button
                        disabled={!selectedSize}
                        onClick={() => {
                          handelCount(
                            "plus",
                            count,
                            0,
                            setMaxQuantity(
                              selectedSize,
                              count,
                              setCount,
                              quantity
                            ),
                            selectedSize,
                            setCount,
                            setstatus
                          ); //handelCount = (proces, Prevcount, min, max)
                        }}
                        className="btn btn-light  button-count  bg-opacity-25 border border-2"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="count-center-cost col-6">
                    <div className="header-count-center mb-2">price total</div>
                    <div className="header-count-total ">
                      {count === 0 ? unitePrice : unitePrice * count} EGP
                    </div>
                  </div>
                </div>

                <div className="actions-buttons  d-flex  align-items-center mt-5">
                  <button
                    className="btn btn-primary col-4"
                    onClick={HandelOrderButton}
                  >
                    ADD TO BAG
                  </button>
                  {/* <button className="btn btn-light col-4 border-dark offset-1">
                    SAVE <BsHeart />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** caroussel related */}
      </div>
    </div>
  );
};
