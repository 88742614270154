import React, { useEffect, useState } from 'react'
import './MyOrders.css'
import { useSelector } from 'react-redux'
import { getArrDataOrder } from '../../functions/firebase/getProductsData'
import { TagS } from '../../Components/TagS/TagS'
import { Looder } from '../../Components/Looder/Looder'
import { Breadcrumbs } from '../../Components/Breadcrumbs/Breadcrumbs'
import { BsPrinter } from 'react-icons/bs'
import { downlaodRecite } from '../../functions/DownloadInvoice/DownloadInvoice'
import { ReciteTemp } from '../../Components/ReciteTemp/ReciteTemp'
export const MyOrders = () => {
  const [data,setData] = useState([])
const auth = useSelector((store)=> store.auth)
const [looding, setlooding] = useState(true);
  useEffect(() => {
    
    getArrDataOrder('orders',auth.email).then((data) =>{ 
      setData(data)
      setlooding(false)
    
    }).catch(err => console.log(err))
  }, [auth.email])
const [show,setShow] = useState(false);
  const handelDownlaodRecite = (id) =>{
    downlaodRecite(id);
    setTimeout(()=>{

      setShow(false);
    },1000)
}


const [order,setOrder] = useState({});
const handelShow = (el) =>{
  setTimeout(()=>{
    setOrder(el);
    setShow(true);
  },1000)
}


  return (
    <>
    {looding?
      <Looder />:
    <section className="my-orders">
  <div className="bg-image h-100 my-orders-img" >
  <div className='py-2 px-4 w-100 text-light ' style={{backgroundColor: "rgba(0,0,0,.45)"}}>
        <Breadcrumbs />
      </div>
    <div className="mask d-flex align-items-center h-100" style={{backgroundColor: "rgba(0,0,0,.45)"}} >

      <div className="container h-100">
        <div className="row justify-content-center h-100">
          <div className="col-12 h-100">
            <div className="card bg-dark shadow-2-strong ">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-dark table-borderless mb-0">
                    <thead>
                      <tr>
                        <th scope="col">#NO</th>
               
                        <th scope="col">CREATED</th>

                      
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((el,i)=>{
                        return(
                        <tr key={i}>
                        <th scope="row">{i+1}</th>
              
                        <td>{el.created}</td>
                       
                        <td><TagS type={el.status} /></td>
                        <td><button className='btn btn-sm btn-light' onClick={()=> handelShow(el)} ><BsPrinter /></button></td>
                      </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {show?
                
                <div className='invoice-window' >
                <div className="w-100 d-flex flex-wrap justify-content-between align-items-center">
                  <div className="invoice-window-body w-100">
                  <div className='invoice-window-buttons py-3 d-flex flex-row justify-content-center align-items-center mb-3'>
 
                      <button onClick={()=>{handelDownlaodRecite(`${order.created}-${auth.name}`)}} type="button" className="btn btn-primary col-3 my-2">download</button>
                      <button onClick={()=>{setShow(false)}} type="button" className="btn opacity-50 btn-danger col-3 offset-1  my-2">Close</button>

                    </div>
                    <div className="modal-body px-1 ">
                     <ReciteTemp order={{...order}}  />
                    </div>

                  </div>
                </div>
              </div>
             :""

}
  </div>
</section>
                    }</>)
}
