// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfigMain = {
  apiKey: "AIzaSyBNzRkpvvfuYdantemy2fN3h9Y4tyYlgP0",
  authDomain: "school-ecommerce.firebaseapp.com",
  projectId: "school-ecommerce",
  storageBucket: "school-ecommerce.appspot.com",
  messagingSenderId: "247491858688",
  appId: "1:247491858688:web:cd6b0f1b2bdf3b7e2a23e5"
};


const firebaseConfig = {
  apiKey: "AIzaSyDRW-3r1f7cDT7Fq80Z4tM9ieav32d6NRw",
  authDomain: "school-backend-c86c7.firebaseapp.com",
  projectId: "school-backend-c86c7",
  storageBucket: "school-backend-c86c7.appspot.com",
  messagingSenderId: "131580464169",
  appId: "1:131580464169:web:9ce83f7a6efb303216e038"
};



// Initialize Firebase
export const App = initializeApp(firebaseConfigMain);
export const db = getFirestore(App);
export const analytics = getAnalytics(App);