import React, { useEffect } from 'react'
import  {BiDonateHeart} from 'react-icons/bi'
import { Navigate, Outlet } from 'react-router'
import { NavBar } from '../sections/NavBar/NavBar'
import { useState } from 'react'
import { LogInFun } from '../functions/store/slices/authSlice'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Profile } from '../pages/Profile/Profile'
import { CartIcon } from '../sections/CartIcon/cartIcon'
import { signOut } from 'firebase/auth'
import { auth } from '../functions/firebase/authentfb'



export const PrivateRoute = () => {
const [authlo,setAuth] = useState({})
const [looding,setlooding] = useState(true)


  useEffect(() => {
    const items = JSON.parse(sessionStorage.getItem('auth'));
    if (items) {
      setAuth(items.email);
      setlooding(false)
    }

  }, [])



  const dispatch = useDispatch()

  useEffect(() => {

    const items = JSON.parse(sessionStorage.getItem('auth'));
    if (items) {
      setAuth(items);
      dispatch(LogInFun(items))

    }
  
  }, [])





    const curentUser = authlo
const [show,setShow] = useState(false)
useEffect(()=>{ 
  if(looding === false){
    if(!authlo.parentemail &&( authlo.gender !== 'male' || authlo.gender !== 'male')){
      setShow(true)
  }}
}, [looding,authlo])


const handleLogout = () => {  

  signOut(auth).then(() => {
  // Sign-out successful.
  sessionStorage.removeItem('auth');
      window.location.href = ("/login");
  }).catch((error) => {
  // An error happened.
  });
}



  return (
    curentUser ?
    <div className='App light'>
    <NavBar />
    <div className={`modal fade bg-dark bg-opacity-75   ${show === true?"show d-block  ":""}`}   id="welcomeMassage" aria-hidden="true" aria-labelledby="modal1" tabIndex="-1">
  <div className="modal-dialog modal-dialog-centered  " style={{width:"75%" }}>
    <div className="modal-content">
      <div className="modal-header flex-column">
        <div className="logOut w-100  text-end">
          <button className='btn  rounded-pill bg-primary bg-opacity-25' onClick={handleLogout}>
          Sign out
          </button>
          
        </div>
        <h1 className="modal-title fs-5 text-primary text-opacity-100" id="modal1">Hi {authlo.name} <BiDonateHeart className='text-danger' /> 
        </h1>
        <hr />
        <p  className='text-primary text-opacity-50 text-capitalize'> please continue your account information ( 
          Parent Email and Parent Phnoe Number) </p>
      </div>
      <div className="modal-body">
        <Profile />
      </div>
    
    </div>
  </div>
</div>
    <CartIcon />
    <ToastContainer />
    <Outlet />
    </div>
     :
    <Navigate to={'/login'} />

  )
}
