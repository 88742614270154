import React, { useEffect, useState } from 'react'
import './Cart.css'

import {  ResetCart } from "../../functions/store/slices/cartSlice";


import { ReactComponent as EmptyIllust } from "./empty-not-css.svg" 
import {TbCircleCheckFilled} from 'react-icons/tb'

import { Link } from 'react-router-dom';
import placeholderImg  from "../../assets/imgs/placeholder.jpg"


import { useSelector , useDispatch } from 'react-redux'
import { postOrder } from '../../functions/firebase/getProductsData';
import { ReciteTemp } from '../../Components/ReciteTemp/ReciteTemp';
import { Breadcrumbs } from '../../Components/Breadcrumbs/Breadcrumbs';
import { handelEdit } from '../../functions/firebase/getUserData';
import { downlaodRecite } from '../../functions/DownloadInvoice/DownloadInvoice';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../functions/firebase/firebase';
export const Cart = () => {


const [dataCartProducts,setCartdataProducts] =useState([])
const CartlState = useSelector(state => state.cart);
const [order,setOrder] = useState({id:false})

const [Totorder,setTotOrder] = useState(0)
useEffect(() => {
    if(CartlState){
        setCartdataProducts(CartlState)
    }
   
}, [CartlState]);

const auth = useSelector(store => store.auth)
const dispatch = useDispatch()
  console.log(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(0,9) + "T"+(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(11,15)) + ":"+ new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(16,19))
      useEffect(() => {
    const total = () => {
        if(dataCartProducts){
        setTotOrder(dataCartProducts.reduce((total,el)=> total + el.totalPrice,0))
        }}

        total()
      },[dataCartProducts])
      
const [disableClk,setDisabledClk] = useState(false)
const [idOrder,setIdOrder] = useState('')
    const handelSubmit = (e) =>{
        e.preventDefault();
        if(disableClk === false){
        if(auth.ownPackage === 'no'){
            postOrder( {
                first:"true",
                customer:auth.email,
                total:Totorder,
                name:auth.name,
                studentId:auth.id,
                status:"pending",
                id: idOrder ? idOrder : 'id not available now',
                type:'',
                created:new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(0,9) + "T"+(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(11,15)) + ":"+ new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(16,19),
                items:Object.assign({}, dataCartProducts?.map(el => {return {
                id:el.id,size:el.size?el.size:null,name:el.name,totalPrice:el.totalPrice,count:el.count === null ? 1 : el.count,type:el.type,status:el.status?el.status:" ",products:el.products?Object.assign({},[...el.products]) :""}}))
                },setIdOrder)
            
            .then(()=> {
                setOrder(
                    {
                        first:"true",
                        customer:auth.email,
                        total:Totorder,
                        name:auth.name,
                        studentId:auth.id,
                        status:"pending",
                        id: idOrder ? idOrder : 'id not available now',
                        type:'',
                        created:new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(0,9) + "T"+(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(11,15)) + ":"+ new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(16,19),                        items:Object.assign({}, dataCartProducts?.map(el => {return {
                        id:el.id,size:el.size?el.size:null,name:el.name,totalPrice:el.totalPrice,count:el.count === null ? 1 : el.count,type:el.type,status:el.status?el.status:" ",products:el.products?Object.assign({},[...el.products]) :""}}))
        
                }
                )
            })
        }else{
                postOrder({
                    customer:auth.email,
                    total:Totorder,
                    name:auth.name,
                    studentId:auth.id,
                    status:"pending",
                    id: idOrder ? idOrder : 'id not available now',
                    type:'',
                    created:new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(0,9) + "T"+(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(11,15)) + ":"+ new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(16,19),                    items:Object.assign({}, dataCartProducts?.map(el => {return {
                    id:el.id,size:el.size?el.size:null,name:el.name,totalPrice:el.totalPrice,count:el.count === null ? 1 : el.count,type:el.type,status:el.status?el.status:" ",products:el.products?Object.assign({},[...el.products]) :""}}))
    
                },setIdOrder)
                .then(()=> {
                    setOrder(
                        {
                 
                            customer:auth.email,
                            total:Totorder,
                            name:auth.name,
                            studentId:auth.id,
                            status:"pending",
                            id: idOrder ? idOrder : 'id not available now',
                            type:'',
                            created:new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(0,9) + "T"+(new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(11,15)) + ":"+ new Date().toLocaleString('en-US', { timeZone: 'Egypt' }).slice(16,19),                            items:Object.assign({}, dataCartProducts?.map(el => {return {
                            id:el.id,size:el.size?el.size:null,name:el.name,totalPrice:el.totalPrice,count:el.count === null ? 1 : el.count,type:el.type,status:el.status?el.status:" ",products:el.products?Object.assign({},[...el.products]) :""}}))
                    }
                    )
                    
                })
        }

        setDisabledClk(true)
    }
     }

useEffect(() => {

    if(idOrder.length > 0) {
        dispatch(ResetCart())
        handelEdit('users',{'allPatment':[...auth.allPatment,order.id]},auth.email)
}
  },[auth.allPatment, auth.email, dispatch, idOrder, order.id])



const CancelOrder = async (id) =>{

    await deleteDoc(doc(db, "orders", id));
    setIdOrder('')
    setOrder({})
}

const handelDownlaodRecite = (filename) =>{
    downlaodRecite(filename);
    setTimeout(()=>{
        setIdOrder('');
    },1000)
    
}
    return (
        <div className='cartPage'>
            <div className="bundel-header ">
        <div className="container h-100">
          <div className="h-100 d-flex flex-column flex-md-row  justify-content-between align-items-center">
            <h1 className="header-page pt-3 pt-md-0">Cart</h1>
            <span>
              <Breadcrumbs />
            </span>
          </div>
        </div>
      </div>
            <div className="container ">


{dataCartProducts.length === 0?
<div className="d-flex justify-content-center align-items-center w-100 h-100">

<div className="empty">
    <h1>Cart is Empty</h1>
    <div className="">
        <Link to='/'>
        <button className="btn btn-outline-warning w-100" >continue shopping</button>
        </Link>
    </div>
    <EmptyIllust />

</div>
</div>:

                <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <div className="col-12 col-md-8">
                    <div className='header-cart'>
                    <div className="d-flex">
                        <div className="col-4">
                            product
                        </div>
                        <div className="col">
                            Price
                        </div>
                        <div className="col">
                            Size
                        </div>
                        <div className="col">
                            QTY
                        </div>
                        <div className="col">
                            Status
                        </div>
                        <div className="col">
                            Total
                        </div>
 
                    </div>
                </div>
<hr />
{!CartlState?"":CartlState.map(({name,category,status,unitePrice,totalPrice,count,size,image,id},i)=>{
  return   <>
  <div className='content-cart mt-2 ' key={i+100}>
                <div className="d-flex align-items-center justify-content-center text-capitalize ">
                    <div className="col-4">
                        <div className="d-flex">
                            <div className="colimage-product-cart-container col-6 d-none">
                                <img className='image-product-cart' src={image || placeholderImg} alt='' />
                            </div>
                            <div className="ps-2 col-6">
                                <h4 className='nameOP'>
                                    {name}
                                </h4>
                                <h4 className='nameOP'>
                                    {category}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {unitePrice}
                    </div>
                    <div className="col">
                        {size || null}
                    </div>
                    <div className="col">
                        {count}
        </div>
                    <div className="col">
                        {status}
        </div>



                    <div className="col">
                        {totalPrice}
                    </div>
                </div>
                </div>
                </>
})}
<hr />
<div className="d-flex justify-content-between">
    <div className="col-5">
        <Link to='/'>
        <button className="btn btn-secondary btn-custom-cart w-100" >continue shopping</button>
        </Link>
    </div>
    <div className="col-3">
    <button className="btn btn-secondary btn-custom-cart w-100" onClick={()=>dispatch(ResetCart())}>Clear cart</button>
    </div>
</div>
                    </div>
                    <div className="col-12 col-md-4 p-3">
                        <div className='recipte'>
                            <div className="recipte-header">
                                <h4 className='recipte-headers'>Name: {auth.name}</h4>
                                <h4 className='recipte-headers'>ID: {auth.id}</h4>
                                <h4 className='recipte-headers'>Grade: {auth.stage}</h4>
                            </div>
                            <hr />
                            <div className="recipte-content">
                                <div className="d-flex">
                                    <div className="col">
                                        Order Total
                                    </div>
                                    <div className="col">
                                    {dataCartProducts?dataCartProducts.reduce((total,el)=>{
                                        return total + el.totalPrice
                                    },0):""}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <button disabled={disableClk} onClick={(e)=>{handelSubmit(e)}} className='btn btn-primary w-100 mt-2'>
                            checkout
                            </button>
                        </div>
                    </div>













  


                </div>
                }



                    
                </div>
{idOrder?
                
                <div className='invoice-window' >
                <div className="">
                  <div className="invoice-window-body">
                    <div className="modal-body">
                     <ReciteTemp order={{...order,id:idOrder}}  />
                    </div>
                    <div className='d-flex justify-content-center flex-row flex-wrap align-items-center mt-3 text-capitalize'>
                        <h1 className='text-success col-12 text-center mb-1' style={{fontSize:"68px"}}><TbCircleCheckFilled /></h1>
                    <h6 className='text-dark text-opacity-50 col-8  text-center px-5 my-1 '>
                      Kindly download the invoice and go to the financial department to pay within 5 working days or else your order will be cancelled
                    </h6>
                      <button onClick={()=>{handelDownlaodRecite(`${order.created}-${auth.name}`)}} type="button" className="btn btn-primary col-4 offset-1 my-2">download</button>
                      <button onClick={()=>{CancelOrder(idOrder)}} type="button" className="text-capitalize btn btn-danger col-4 offset-1  my-2">Cancel order</button>
                    </div>
                  </div>
                </div>
              </div>
             :""

}
            </div>
       
    )
}
