import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import {toast} from 'react-toastify'

// Add a new document in collection "cities"
export const options = {
  
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    
}

export const getUserData = async (userId) =>{
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
    return docSnap.data();
} else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
}

export const handelEdit  = async (file,data,id) => {
    try {
      const docRef = await updateDoc(doc(db, file,id), {
        ...data    
      })
      //toast.success(`${id} updated To ${Object.values(data)[0]} successfully`,options)

    } catch (e) {

     //toast.error(`${id} still ${Object.values(data)[0]}`,options)
    }
    }