import React, { useEffect, useRef, useState } from 'react'
import { doc, updateDoc } from "firebase/firestore";
import {db} from '../../functions/firebase/firebase'
import './Profile.css'
import { toast } from 'react-toastify';
import { options } from '../../App';
export const Profile = () => {

  const intialData = {
    name:"",id:"",stage:"",gender:"",stagelevel:"",parentemail:"",parentphone:""
  }
const [userData,setUserData] = useState(intialData)
const [inabaleEdie,setinabaleEdie] = useState(true)

    useEffect(() => {
  
      const items = JSON.parse(sessionStorage.getItem('auth'));
      if (items) {
        setUserData(items);
      }
    }, [])
  
const hanelEdite = () =>{
  
    setinabaleEdie(!inabaleEdie);
    if(inabaleEdie === true){
        handelSumbit();
    }
    else if(inabaleEdie === false){
      setinabaleEdie(!inabaleEdie);
    }

}

const handelSumbit = async (e)   =>{
  e.preventDefault();
 if(userData.gender.length == 0){
  toast.warn('You must provide a gender')
 }  else if(userData.parentphone === ""){
  toast.warn('You must provide a parent phone')
 }  else if(userData.parentemail === ""){
  toast.warn('You must provide a parent email')
 } else{
    const washingtonRef = doc(db, "users", userData.id+'@school.sys');
    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      ...userData
  })
  sessionStorage.setItem('auth', JSON.stringify(userData));
  toast.success(`Good,Enjoy shopping  !`,options );
  window.location.reload()
  }

}
 const prevCountRef = useRef({});
  useEffect(() => {
    /**
     * assign the latest render value of count to the ref
     * However, assigning a value to ref doesn't re-render the app
     * So, prevCountRef.current in the return statement displays the
     * last value in the ref at the time of render i.e., the previous state value.
     */
    prevCountRef.current = userData;
  }, [userData]); //run this code when the value of count changes
  return (
    <div className='profile'>
       <form className=""  onSubmit={(e)=>{handelSumbit(e)}}>
        <div className="container px-md-4 px-0">
            <div className="upper h-100 bg-primary bg-opacity-75 p-2 px-0">
                <div className="d-flex flex-wrap justify-content-between h-100 align-items-center ps-3">
                    <div className="col-sm-2 col-0   rounded-avtar"></div>
                    <div className="col-12 col-sm-7  ps-3">
                        
                        <h3 className='name text-capitalize'>{userData.name}</h3>
                        <h3 className='id'>{userData.id}</h3>
                        <h3 className='id'>{userData.gender}</h3>
                    </div>
                    <div className="col-sm-3 col-12 text-end pe-4 mt-3">
                        <button className='btn btn-primary butt w-100' type='submit' >
                               Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className="profile-form h-100 bg-secondary bg-opacity-10 pt-4 px-3 px-sm-5">
               

                <div className="row   mb-3">
  <label htmlFor="colFormLabelName" className="col-12 text-dark text-opacity-25 fw-bolder col-md-3 col-form-label col-form-label px-0">Name</label>
  <div className="col-12 col-md-5">
    <p autoComplete='false' autoCorrect='false' readOnly type="text" className={`text-primary text-opacity-75 form-control form-control-plaintext`} id="colFormLabelName"  >{userData.name}</p>
  </div>
  </div>

    <div className="row  mb-3">
  <label htmlFor="colFormLabelGender" className="col-12 text-dark text-opacity-25 fw-bolder col-md-3 col-form-label col-form-label px-0">Gender</label>
  <div className="col-12 col-md-5">
  <select required  readOnly={!inabaleEdie}  className={`text-primary text-opacity-75 form-control ${!inabaleEdie?'form-control-plaintext':"form-select"} `} aria-label="Default select example" onChange={(e)=>{setUserData({...userData,gender:e.target.value})}} value={userData.gender || 'choose your gender'}>
  <option selected>choose your gender</option>
  <option value="male">Male</option>
  <option value="female">Female</option>

</select>
  </div>
  </div>
                <div className="row  mb-3">
  <label htmlFor="colFormLabelStage" className="col-12 text-dark text-opacity-25 fw-bolder col-md-3 col-form-label col-form-label px-0 " >Stage:{userData.stage}</label>
  <div className="col-12 col-md-5">
  <select  readOnly={!inabaleEdie} className={`text-primary text-opacity-75 form-control ${!inabaleEdie?'form-control-plaintext':"form-select"} `} aria-label="Default select example" onChange={(e)=>{setUserData({...userData,stagelevel:e.target.value})}} value={userData.stagelevel}>
 {userData.stage === 'KG' ?
 <>
   <option value="Pre-school">Pre-school</option>
   <option value="KG1">KG1</option>
   <option value="KG2">KG2</option>
   </>
   :
   ""
}
 {userData.stage === 'Prim' ?
 <>
   <option value="Primary1">Primary 1</option>
   <option value="Primary2">Primary 2</option>
   <option value="Primary3">Primary 3</option>
   <option value="Primary4">Primary 4</option>
   <option value="Primary5">Primary 5</option>
   <option value="Primary6">Primary 6</option>
   </>
   :
   ""
}
 {userData.stage === 'Prep' ?
 <>
  <option value="Preparatory-1">Preparatory-1</option>
  <option value="Preparatory-2">Preparatory-2</option>
  <option value="Preparatory-3">Preparatory-3</option>
   </>
   :
   <>
   <option value="Secondary-1">Secondary-1</option>
   <option value="Secondary-2">Secondary-2</option>
   <option value="Secondary-3">Secondary-3</option>
    </>
   
}




</select>
  </div>
  </div>

                <div className="row  mb-3">
  <label htmlFor="colFormLabelparent" className="col-12 text-dark text-opacity-25 fw-bolder col-md-3 col-form-label col-form-label px-0">Parent Email </label>
  <div className="col-12 col-md-5">
    <input autoComplete='false' autoCorrect='false'  readOnly={!inabaleEdie} type="email" required  className={`text-primary text-opacity-75 form-control ${!inabaleEdie?'form-control-plaintext':"form-control"} `} id="colFormLabelparent" placeholder="" onChange={(e)=>{setUserData({...userData,parentemail:e.target.value})}} value={userData.parentemail}/>
  </div>
  </div>
                <div className="row mb-3">
  <label htmlFor="colFormLabelPhnoe" className="col-12 text-dark text-opacity-25 fw-bolder col-md-3 col-form-label col-form-label px-0">Parent Phone Number</label>
  <div className="col-12 col-md-5">
    <input autoComplete='false' autoCorrect='false'  readOnly={!inabaleEdie} type="number" required className={`text-primary text-opacity-75 form-control ${!inabaleEdie?'form-control-plaintext':"form-control"} `} id="colFormLabelPhnoe" placeholder="" onChange={(e)=>{setUserData({...userData,parentphone:e.target.value})}} value={userData.parentphone} />
  </div>
  </div>
              
            </div>
             
        </div>
       </form>
    </div>
  )
}
