import { addDoc, collection, collectionGroup, doc, getDoc, getDocs, increment, query, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { handelEdit } from "./getUserData";
import { toast } from "react-toastify";

export  const getArrData = async (file,gender,stage) =>{

    const dataArr = query(collectionGroup(db, file));
    const querySnapshot =  await getDocs(dataArr);
    let arr = []
    querySnapshot.forEach((doc) => {
        if((doc.data().gender === (gender) ||  doc.data().gender === ('both') ) && (doc.data().stage)?.includes(stage)  ){
          arr.push({...doc.data(),id:doc.id})
        }
        
    });
     return arr
  }
export  const getArrDataOrder = async (file,Studentid) =>{

    const dataArr = query(collectionGroup(db, file));
    const querySnapshot =  await getDocs(dataArr);
    let arr = []
    querySnapshot.forEach((doc) => {
        if( doc.data().cutsomer === Studentid   ){
          arr.push({...doc.data(),id:doc.id})
        }
        
    });
     return arr
  }

export  const getSpecificIdData = async (file,id) =>{
    const docRef = doc(db, file, id);
  const docSnap = await getDoc(docRef);
  let data = {}
  if (docSnap.exists()) {
    data = {...docSnap.data(),id:id};
return data
  
  } else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
  }
  }

  //get quanitty and sizes for specific id product
export  const getSpecificIdProduct = async (file,id) =>{
    const docRef = doc(db, file, id);
  const docSnap = await getDoc(docRef);
  let data = []
  if (docSnap.exists()) {
    data = {quantity:docSnap.data().quantity,sizeGuide:docSnap.data().sizeGuide,images:docSnap.data().images};
return data
  
  } else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
  }
  }
  const editeall = async (data) => {
    if(data){    
    Object.keys(data.items).forEach((el)=>{
        if(data.items[el].type === 'item'){
            updateDoc(doc(db, "products", data.items[el].id), 
        {[`quantity.${data.items[el].size}`]: increment(- data.items[el].count)}).then(()=>{
               
            })
        }
        else if(data.items[el].type === 'package'){
           
            Object.keys(data.items[el].products).forEach((els)=>{
                updateDoc(doc(db, "products",data.items[el].products[els].id), {[`quantity.${data.items[el].products[els].size}`]: increment(data.items[el].products[els].count)}).then(()=>{
                    
                })
            })
        }
    })
    }
  }
export const postOrder = async (data,getId) => {
    try {
      const docRef = await addDoc(collection(db, "orders"), {
        ...data,    
      }
      ).then((data) =>{
      
  getId(data.id)
}
      ).finally(()=>{
           editeall(data)
      })
    } catch (e) {
      return console.error("Error adding document: ", e);
    }
  
}
