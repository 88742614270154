import React from 'react'
import './ReciteTemp.css'

import { TagS } from '../TagS/TagS';

export const ReciteTemp = ({order,ref}) => {
    const { created ,name,  items  ,status ,studentId} = order;
    


  return (
    <div className='recite d-flex flex-column justify-content-between align-items center ' ref={ref} id='invoice'>
    <div className="recite-header pt-5">
        <div className="px-5 brand-information d-flex justify-content-between align-items-center">
            <h4 className='recite-brand text-primary'>
                S <span className='recite-brand-decoration text-dark'>ZONE</span> 
            </h4>
            <h4 className='head-brand'>
              INVOICE
            </h4>
        </div>

        <div className="px-5 information mt-5 d-flex  flex-column justify-content-start text-start align-items-start">
          
            <h6 className="name my-3 text-dark text-opacity-25 ">Invoice to: <span className="value text-dark text-opacity-100 fw-bolder"> {name}</span> </h6>
            <h6 className="name my-1 text-dark text-opacity-25 ">Invoice to Id: <span className="value text-dark text-opacity-100 fw-bolder"> {studentId}</span> </h6>
            <h6 className="name my-2 text-dark text-opacity-25">Date / Time: <span className="value text-dark text-opacity-100 fw-light"> {created}</span></h6>
            <h6 className="name my-2 text-dark text-opacity-25">Order Status: <span className="value text-dark text-opacity-100 fw-light"> <TagS type={status} /> </span></h6>
            
            </div>
    </div>
    <div className="recite-body">
    <table className="table table-cutome table-borderless  ">
<thead className='table-dark tab-head'>
<tr>
  <th scope="col">#</th>

  <th scope="col">name</th>
  <th scope="col">type</th>
  <th scope="/col text-center">QTY</th>
  <th scope="col text-center">size</th>
  <th scope="col text-center">status</th>

</tr>
</thead>
<tbody className='table-group-divider body-values'>
{Object.keys(order.items)?.map((el,key)=>{
return    items[el].type === "item"
   ?
   
        <tr key={key} className='table-secondary'>
        <th scope="row">{key+1}</th>

        <td >{items[el].name}</td>
        <td >{items[el].type}</td>
        <td className='text-center'>{items[el].count}</td>
        <td className='text-center'>{items[el].size}</td>
        <td className='text-center'>{items[el].status}</td>

      </tr>
  :
  <>
  <tr key={key} className='table-secondary'>
  <th scope="row">{key+1}</th>

  <td >{items[el].name}</td>
  <td >{items[el].type}</td>
  <td className='text-center'>{items[el].count}</td>
        <td className='text-center'>{items[el].size}</td>

</tr>

  {Object.keys( items[el].products)?.map((els,is)=>{

    return  <tr key={is} className='  '>
           
  
      
 
    <th scope="row">{key+1 + '-' + (is+1)}</th>
 
      <td >{items[el].products[els].name}</td>
      <td className='text-center'>{items[el].products[els].count}</td>
      <td className='text-center'>{1}</td>
      <td className='text-center'>{items[el].products[els].size}</td>
      <td className='text-center'>{items[el].products[els].status}</td>
    


      </tr>
    })
    
    
}



    
    </>
}

)
}
<tr className='table-dark tab-head '>
  <th></th>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
</tr>

</tbody>

</table>
    </div>
    <div className="recite-footer px-3 py-3 text-center text-primary text-opacity-50 fs-6 fw-bolder">
    
    Kindly download the invoice and 
    go to the financial department to pay within 5 working days or else your order will be cancelled

    </div>
    </div>

  )
}
