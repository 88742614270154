import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "./responsive.css";

import { LandingPage } from "./pages/LandingPage/LandingPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "./pages/LogIn/Login";
import { Products } from "./pages/Products/Products";
import { Cart } from "./pages/Cart/Cart";

import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";
import { useDispatch } from "react-redux";
import { PrivateRoute } from "./Components/PrivateRoute";
import { SingleProductPage } from "./pages/SingleProductPage/SingleProductPage";
import { logEvent } from "firebase/analytics";

import { PackPage } from "./pages/Packs/PackPage/PackPage";
import { PackSinglePage } from "./pages/Packs/PackSinglePage/PackSinglePage";
import { useEffect, useState } from "react";
import { PrivateRoutePack } from "./Components/PrivateRoutePack";
import { LogInFun } from "./functions/store/slices/authSlice";

import { MyOrders } from "./pages/MyOrders/MyOrders";
import { Profile } from "./pages/Profile/Profile";
import { analytics } from "./functions/firebase/firebase";
import { Bundels } from "./pages/Bundels/Bundel";
import { SingleBundel } from "./pages/Bundels/SingleBundel/SingleBundel";

export const options = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
function App() {
  logEvent(analytics, "notification_received");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [auth, setAuth] = useState({});
  useEffect(() => {
    const items = JSON.parse(sessionStorage.getItem("auth"));
    if (items) {
      setAuth(items);
      dispatch(LogInFun(items));
    } else if (!items || items === undefined) {
      navigate("./login");
    }
  }, [dispatch, navigate]);

  const messageLoader = "Looding 🚀🚀";
  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<Cart />} path="/Cart" />
          <Route element={<MyOrders />} path="/MyOrders" />

          <Route element={<NotFoundPage />} path="*" />
          <Route
            element={<LandingPage />}
            path="/"
            loader={messageLoader}
            handle={{
              crumb: () => "Home",
            }}
            exact
          />


          <Route
            element={
              <PackPage
                dataPage={{
                  header: "packages",

                  name: auth.name,
                  purpose: "packages",
                }}
                loader={messageLoader}
                handle={{
                  crumb: "Bundels",
                }}
              />
            }
            path="/Packages"
          />

          <Route
            element={<SingleBundel />}
            path="/Packages/:id"
            loader={messageLoader}
            handle={{
              crumb: (data) => <span>{data.threadName}</span>,
            }}
          />
          

          <Route element={<PrivateRoutePack />}>
            <Route
              element={<SingleProductPage />}
              path="/Items/:id"
              loader={messageLoader}
              handle={{
                crumb: (data) => <span>{data.threadName}</span>,
              }}
            />

            <Route
              exact
              element={<Products dataPage={{ header: "Items" }} />}
              path="/Items"
              loader={messageLoader}
              handle={{
                crumb: (data) => <span>{data.threadName}</span>,
              }}
            />
          </Route>

          <Route element={<Profile />} path="/profile" />
        </Route>

        <Route element={<Login />} path="/login" />
      </Routes>
    </>
  );
}

export default App;
