import React, { useEffect, useState } from 'react'
import { BsCart, BsCartFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const CartIcon = () => {


    const cartState = useSelector((state)=> state.cart)
    const [cartLength,setCartLength] = useState(cartState.length)



    useEffect(() => {
      setCartLength(cartState.length);
    }, [cartState.length])



  return (
    <div className="cart flycart col-12 col-md text-center my-2 my-md-0   ">
    <Link to="/Cart" className="d-block text-decoration-none icon">
      {cartLength <= 0 ? <BsCart  /> :
      <div className=''>
      <BsCartFill  />
      <div className='bubble'>
        {cartLength}
      </div>
      </div>
      }
    </Link>
  </div>
  )
}
