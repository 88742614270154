import React, { useEffect, useState } from "react";
import "./PackPage.css";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "../../../Components/Breadcrumbs/Breadcrumbs";
import Packcard from "../Packcard/Packcard";
import { getArrData } from "../../../functions/firebase/getProductsData";
export const PackPage = () => {
  const {gender,stage} = useSelector((state) => state.auth);


  const [data, setData] = useState([]);
  
  useEffect(()=>{
    getArrData('packages',gender,stage).then(data =>
      setData(data)
      )
      
  },[gender,stage])

  return (
    <div className="bundel w-100">
      <div className="bundel-header ">
        <div className="container h-100">
          <div className="h-100 d-flex flex-column flex-md-row  justify-content-between align-items-center">
            <h1 className="header-page pt-3 pt-md-0">ALL Packages</h1>
            <span>
              <Breadcrumbs />
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-wrap">
          {data
            ?.map((pack, i) => {
                return (
                  <div className="col-12 col-md-6 mt-3" key={i}>
                        <Packcard pack={pack} />
                  </div>
                );
              })
            }
        </div>
      </div>
    </div>
  );
};
