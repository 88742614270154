import React from 'react'
import './NotFoundPage.css'
import { Link } from 'react-router-dom'
export const NotFoundPage = () => {
  return (
    <div className='NotFoundPage'>
        <div className="container h-100">
            <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                <h1 className='head-not'>404</h1>
                <h2 className='subhead-not'>PAGE NOT FOUND</h2>
                <p className='paragraph-not'>
                    The page you are looking for might have been removed, had its name changed or is temporarily unavailable
                </p>
                <Link to={'/'}>
                <button className='btn btn-info '>
                    HOME PAGE
                </button>
                </Link>

            </div>
        </div>
    </div>
  )
}
