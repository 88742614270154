import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { App } from "./firebase";
import { getUserData } from "./getUserData";
import { toast } from "react-toastify";


export const auth = getAuth(App);

export const loginFunction = ( Id, password,setmessageValid,setUserData) => {

     // eslint-disable-next-line react-hooks/rules-of-hooks


return(
    getUserData(Id).then((res)=>{
      if(res !== 'No such document!'){
        setUserData(res)
        setmessageValid('is-invalid')
      } else{
        setmessageValid('is-invalid')
      }
      
    }).catch((error) => {
      setmessageValid('is-invalid')
    })
    
   

  )
// return(
//   signInWithEmailAndPassword(auth, Id, password) 
//   .then((userCredential) => {
//     const user = userCredential;
    
//     getUserData(user.user.email).then((res)=>{
//       if(res !== 'No such document!'){
//         setUserData(res)
//         setmessageValid('is-invalid')
//       } else{
//         setmessageValid('is-invalid')
//       }
   
      
//     }).catch((error) => {
//       setmessageValid('is-invalid')
//     })
    
   
//   })
//   .catch((error) => {
//     setmessageValid('is-invalid')
//   })
//   )

}

