import {  configureStore} from '@reduxjs/toolkit';
import cartSlice from './slices/cartSlice';
import  userSlice  from './slices/authSlice';




export const store = configureStore({
    reducer: {
        cart: cartSlice,
        auth: userSlice,
    },
})