export const vlogologin = (
  <svg width="100%" height="100%" viewBox="0 0 350 358" className="css-1j8o68f">
    <defs id="SvgjsDefs4534"></defs>
    <g
      id="SvgjsG4535"
      featurekey="rootContainer"
      transform="matrix(1,0,0,1,0,0)"
      fill="#fff"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        d=" M0,0 H350 V358 H0,0 z M2,2 H348 V356 H2,2 z M6,6 H344 V352 H6 z M8,8 H342 V350 H6,8 z "
      ></path>
    </g>
    <g
      id="SvgjsG4536"
      featurekey="symbolFeature-0"
      transform="matrix(1.9461691328357837,0,0,1.9461691328357837,112.72356401131306,47.70935884156373)"
      fill="#fff"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path
              fill="#fff"
              d="M17.362,4.676c5.027,11.327,7.524,29.99,8.718,43.127c-5-10.232-11.878-17.892-14.759-20.856l4.898-5.969     l1.349-1.643l-1.722-1.246l-6.535-4.728L17.362,4.676 M17.878,1.177l-11.57,12.48l8.365,6.051l-6.052,7.375     c0,0,16.732,15.693,20.471,35.738C29.092,62.822,27.667,19.331,17.878,1.177L17.878,1.177z"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M46.639,4.676l8.051,8.686l-6.534,4.727l-1.723,1.246l1.349,1.643l4.897,5.969     c-2.88,2.964-9.758,10.625-14.758,20.856C39.114,34.667,41.61,16.002,46.639,4.676 M46.122,1.177     c-9.789,18.153-11.213,61.645-11.213,61.645c3.738-20.045,20.47-35.738,20.47-35.738l-6.052-7.375l8.364-6.051L46.122,1.177     L46.122,1.177z"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#fff"
              d="M25.602,5.024l1.889,0.896l-1.834,0.625C25.638,6.056,25.62,5.537,25.602,5.024 M23.525,1.826     c0,1.869,0.24,7.475,0.24,7.475l9.038-3.078L23.525,1.826L23.525,1.826z"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M38.398,5.024c-0.018,0.513-0.036,1.031-0.055,1.52L36.51,5.919L38.398,5.024 M40.476,1.826L31.2,6.223     l9.036,3.078C40.235,9.301,40.476,3.695,40.476,1.826L40.476,1.826z"
            ></path>
          </g>
        </g>
      </g>
    </g>
    <g
      id="SvgjsG4537"
      featurekey="nameFeature-0"
      transform="matrix(4.016709403246893,0,0,4.016709403246893,50,146.78019824044642)"
      fill="#fff"
    >
      <path d="M5.48 40.24 c-3.04 0 -5.48 -2.48 -5.48 -5.48 l0 -4.36 l3.88 0 l0 4.36 c0 0.88 0.72 1.6 1.6 1.6 s1.56 -0.72 1.56 -1.6 l0 -3.8 l-7.04 -9.72 l0 -4.96 c0 -3.04 2.44 -5.52 5.48 -5.52 c3 0 5.44 2.48 5.44 5.52 l0 4.32 l-3.88 0 l0 -4.32 c0 -0.92 -0.68 -1.6 -1.56 -1.6 s-1.6 0.68 -1.6 1.6 l0 3.64 l7.04 9.72 l0 5.12 c0 3 -2.44 5.48 -5.44 5.48 z M22.84 36.28 l-0.08 3.72 l-9.84 0 l4.16 -24.84 l-4.08 0 l0 -3.72 l8.56 0 l-4.16 24.84 l5.44 0 z M30.240000000000002 40.04 c-3 0 -5.4 -2.44 -5.4 -5.4 l0 -17.96 c0 -2.96 2.4 -5.36 5.4 -5.36 c2.96 0 5.36 2.4 5.36 5.36 l0 17.96 c0 2.96 -2.4 5.4 -5.36 5.4 z M30.240000000000002 15.120000000000001 c-0.88 0 -1.56 0.72 -1.56 1.56 l0 17.96 c0 0.88 0.68 1.56 1.56 1.56 c0.84 0 1.56 -0.68 1.56 -1.56 l0 -17.96 c0 -0.84 -0.72 -1.56 -1.56 -1.56 z M46.88 33.44 l0.16 -22.16 l3.84 0 l-0.24 28.88 l-5.96 0 l-3.24 -22.36 l0 22.36 l-3.84 0 l0 -28.88 l6.2 0 z M62.2 24.92 l-5.52 0 l0 11.28 l5.56 0 l0 3.8 l-9.36 0 l0 -28.56 l9.36 0 l0 3.8 l-5.56 0 l0 5.88 l5.52 0 l0 3.8 z"></path>
    </g>
  </svg>
);
