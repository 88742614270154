import React from 'react'
import './Packcard.css'
import placeholderImg  from "../../../assets/imgs/placeholder.jpg"

import { Link } from 'react-router-dom'
const Packcard = ({pack}) => {

const {name,coverImage,id,unitePrice} = pack; 



    return (
    <div className='bundelCard' >

        <div className="d-flex flex-column bundelCard-container">
        <Link to={`/Packages/${id}`} >
            <div className="col-12 image-container">
               
                <img src={coverImage || placeholderImg} className='image' alt={name} width={'100%'}  />
                
            </div>
            <div className="col-12 content-container px-2 ">
                <div className="d-flex justify-content-between flex-row-reverse align-items-center text-end ">
                    <div className="col-6">
                    <h2 className='h5 title'> {name}</h2>
                    </div>
                    <div className="col-6  text-start  p-1 bundel-price  rounded-pill">
                    <h4 className='title h5'><span className='price'>{unitePrice}</span>  EGP</h4>
                    </div>
                </div>
            </div>
            </Link>
        </div>

        
    </div>
  )
}

export default Packcard;