import React, { useState } from 'react'
import './SingleBundel.css'
import { Breadcrumbs } from '../../../Components/Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { BsBag, BsCheck, BsHeartFill, BsLock } from 'react-icons/bs'
import { getSpecificIdData } from '../../../functions/firebase/getProductsData'
import { ProductCardPack } from '../../Packs/PackSinglePage/ProductCardPack'
import { useDispatch } from 'react-redux'
import { AddToCart } from '../../../functions/store/slices/cartSlice'
export const SingleBundel = () => {
 
  const param = useParams();

  const [dataBundel,setDateBundel] = useState({});

  const [order,setOrder] = useState({products:[{size:"",name:''}]})
  const intialBtn = {status:'primary',title:'Add To Bag',icon:<BsBag /> }
  const [buttonMes,setbuttonMes] = useState(intialBtn)

  useEffect(()=>{
    getSpecificIdData('bundels',param.id).then(data => setDateBundel(data))
  },[param.id])
 const {name,items,stage,unitePrice,id,coverImage,category} = dataBundel; 
  useEffect(()=>{
    setOrder({
      name:name,
      unitePrice,
      totalPrice:parseInt(unitePrice,10),
      id,
      type:"bundel",
      image: coverImage,
      category,
      count:1,
      products:items?.map(elpro => {return {id:elpro.id,size:"",status:'pending',count:1,name:elpro.name}})
    })
  },[category, coverImage, dataBundel, id, items, name, unitePrice])

const dispatch = useDispatch()

  const handelSubmitOrder = () =>{
    if(order.products){
      if(order.products.filter(el => el.size).length === order.products.length){
        dispatch(AddToCart(order));
        setOrder({})
        setbuttonMes({status:'success',title:'Order added successfully',icon:<BsCheck />})
      }else{
        
        setbuttonMes({status:'danger',title:'Select sizes firstly',icon:<BsLock />})
      }
    
    }

  }
  const [disabledState,setDisabledState] = useState(true);



  return (
    <div className='SingleBundel'>
      <div className="page-header">
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <h1 className='header'>
              {name}
            </h1>
            <span>
              <Breadcrumbs />
            </span>
          </div>
        </div>
      </div>
      <div className="page-content container">
        <div  className="bundel-controls d-flex flex-wrap justify-content-between align-items-start">
          <div className="col-12 col-md-6 d-flex flex-wrap justify-content-between">
          <h2 className=' price'>Price: {unitePrice} EGP</h2>
          <span className='h6 bg-info bg-opacity-25 rounded-pill px-2 py-1'>{items?.length} items</span>
          <span className='h6 bg-info rounded-pill bg-opacity-25 px-2 py-1'>{stage} </span>
          <ul className='col-12 '>

   


          </ul>
          </div>
          
          <div className="col-12 col-md-6 d-flex flex-column  align-items-center align-items-md-end">
          <button className={`btn btn-${buttonMes.status} w-50 mb-3 `} onClick={handelSubmitOrder} >{buttonMes.title} {buttonMes.icon}</button>
          <button className="btn btn-light w-50 border border-2"  >Add To Favourate <BsHeartFill className='text-danger' /></button>
          </div>
        </div>
      
      <div className=" container ">
        <div className="d-flex flex-wrap">
            {items?.map((el,li)=>{
              return (
                  <div className='px-3 col-12 col-md-4 ' key={li}>
                  <ProductCardPack item={el} Packorder={order} setPackOrder={setOrder} li={li}  bundelId={param.id} type='bundel' />
                  </div>
              )
            })}
</div>

      </div>
    </div></div>
  )
}
