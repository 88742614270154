import React, { useEffect } from 'react'

import { Navigate, Outlet } from 'react-router'
import { NavBar } from '../sections/NavBar/NavBar'
import { useState } from 'react'
import { Looder } from './Looder/Looder'
import { useDispatch } from 'react-redux'
import { LogInFun } from '../functions/store/slices/authSlice'

export const PrivateRoutePack = () => {
const [Pack,setPack] = useState()
const [Looding,setLooding] = useState(true)


  useEffect(() => {
    const items = JSON.parse(sessionStorage.getItem('auth'));
    if (items) {
        setPack(items.ownPackage);
        setLooding(false)
    }
  }, [])
  


  const dispatch = useDispatch()
const [auth,setAuth] = useState({})
  useEffect(() => {

    const items = JSON.parse(sessionStorage.getItem('auth'));
    if (items) {
      setAuth(items);
      dispatch(LogInFun(items))
    }
  
  }, [])

  return (
    Looding ?
    <Looder />
    :
    Pack === "yes" ?
    <>   
    <Outlet />
</>

     :
     <Navigate to={'/'} />
  )
}
