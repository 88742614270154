import React, { useEffect } from 'react'
import './Looder.css'
export const Looder = () => {
  useEffect(() => {
    window.screenTop = 0
  }, [])
  
  return (
    <div className='looder flex-column'>
        <div className="progress"></div>
        <h1 className='brand'>SZONE</h1>
        
    </div>
  )
}
