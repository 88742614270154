import React, { useEffect, useState } from 'react'
import './Products.css'
import { NavBar } from '../../sections/NavBar/NavBar'
import { RxCross1 } from 'react-icons/rx'
import { AccordionFilter } from '../../Components/AccordionFilter/AccordionFilter'
import { ProductCard } from './ProductCard/ProductCard'
import { useSelector } from 'react-redux'
import { collectionGroup, getDocs, query } from 'firebase/firestore'
import { db } from '../../functions/firebase/firebase'
import { Navigate, useNavigate } from 'react-router'
import { getArrData } from '../../functions/firebase/getProductsData'
import { Looder } from '../../Components/Looder/Looder'
import { Breadcrumbs } from '../../Components/Breadcrumbs/Breadcrumbs'
import { legacy_createStore } from '@reduxjs/toolkit'
export const Products = () => {

  const [data, setData] = useState([]);
  const [looding, setlooding] = useState(true);
  const auth = useSelector((store)=> store.auth)

  
  useEffect(()=>{
    getArrData('products',auth.gender,auth.stage).then(data =>

      { 
        setData(data)
        setlooding(false)
      });
  },[])



  return (
    <div className="products-page">
      {looding?
    <Looder />
    :
        <>
              <div className="bundel-header ">
        <div className="container h-100">
          <div className="h-100 d-flex flex-column flex-md-row  justify-content-between align-items-center">
            <h1 className="header-page pt-3 pt-md-0">ALL Items</h1>
            <span>
              <Breadcrumbs />
            </span>
          </div>
        </div>
      </div>
    
      <div className="container">

     {/** down cover */}
    <div className='d-flex h-100 '>


      <div className="col-12 products-group d-flex flex-wrap">
      {data?.map((product,i)=>{
        return <div key={i} className="col-12 col-md-6 col-lg-4 px-3"><ProductCard product={product}  /></div>
      })}
      </div>
            </div>
            </div>
            
            </>}
    </div>
  
  )
}
