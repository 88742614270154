import React from 'react'
import { BsHouse } from 'react-icons/bs';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css'
export const Breadcrumbs = () => {
    let location = useLocation();

      let curentLink = '';

      const crumbs = location.pathname
      .split('/')
      .filter(crumb => crumb !== '')
      .map(crumb =>{
        curentLink += `/${crumb}`;
      
        return (
            <li className="breadcrumb-item" key={crumb}><Link className='breadcrumb-item-link'  to={curentLink}>{crumb}</Link></li>
        )
    
    
    })
    
return(
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item">
        <Link className='breadcrumb-item-link' to={'/'}>
            <BsHouse /> Home
            </Link>
        </li>
  {crumbs}
  </ol>
</nav>
)
}
