import download from "downloadjs";
import * as htmlToImage from 'html-to-image';
import { toast } from "react-toastify";

export const downlaodRecite = (id) =>{

    let node = document.getElementById('invoice')
    htmlToImage.toPng(node)
    .then(function (dataUrl) {
      download(dataUrl, id);
    })
    .catch(function (error) {
      toast.error('Oops, something went wrong')
    });
}