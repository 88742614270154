import React, { useEffect, useState } from 'react'
import "./NavBar.css"
import { BsCart, BsCartFill} from "react-icons/bs"
import { FaHandSparkles} from "react-icons/fa"
import { Link, NavLink } from 'react-router-dom'
import { auth } from '../../functions/firebase/authentfb'
import { signOut } from 'firebase/auth'
import { useSelector } from 'react-redux'
import { hlogologin } from '../../assets/imgs/logos/hlogo'
import { TbMenu } from 'react-icons/tb'
export const NavBar = () => {

  const cartState = useSelector((state)=> state.cart)
  const [cartLength,setCartLength] = useState(cartState.length)
  useEffect(() => {
    setCartLength(cartState.length);
  }, [cartState.length])
  
  const handleLogout = () => {               
    signOut(auth).then(() => {
    // Sign-out successful.
    sessionStorage.removeItem('auth');
        window.location.href = ("/login");
    }).catch((error) => {
    // An error happened.
    });
}

const [authLol,setAuthLo] = useState({})
  useEffect(() => {

    const items = JSON.parse(sessionStorage.getItem('auth'));
    if (items) {
      setAuthLo(items);
    }
  }, [])








  return (
<nav className="navbar-expand-md  d-flex flex-wrap bg-transparent align-items-center justify-content-around px-5 pt-3 w-100">
  <div className='d-flex align-items-center justify-content-md-center justify-content-between flex-wrap w-100'>
  <Link to="/" className="navbar-brand custom text-capitalize  col-2 me-0 me-md-5 ">
        {hlogologin}
        </Link>
        <button className="navbar-toggler col-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon">
        <TbMenu />
      </span>

    </button>
  </div>


    <div className="collapse navbar-collapse  flex-wrap bg-transparent align-items-center justify-content-between px-5 pt-3 w-100" id="navbarTogglerDemo01">
        <ul className="nav custom my-4 py-2 py-lg-auto my-lg-0 col-12 col-md-6   mb-2  text-center  mb-md-0">
          <NavLink to="/" exact='true' className="nav-link px-2  col-12 mx-2 col-md-2 ">Home</NavLink>
          <NavLink to="/Packages" className="nav-link px-2 col-12 mx-3 col-md-2">Package</NavLink>
          <NavLink to="/Items" className="nav-link px-2 col-12 mx-2 col-md-2">Items</NavLink>
        </ul>

<div className='d-flex col-12 flex-wrap justify-content-end  col-md-6 '>
        <div className="cart col-12 col-md-2 text-center my-2 my-md-0   ">
          <Link to="/Cart" className="d-block text-decoration-none icon">
            {cartLength <= 0 ? <BsCart  /> :
            <div className=''>
            <BsCartFill  />
            <div className='bubble'>
              {cartLength}
            </div>
            </div>
            }
          </Link>
        </div>




<div className="prof dropdown  cart col-12 col-md-7 text-center my-2 my-md-0 ">
  <button className="btn fs-4 py-0 m-0  w-100 d-flex align-items-center border-0"  data-bs-toggle="dropdown" aria-expanded="false">
  

  <span className='fs-6 flex-1 me-2'> {authLol.name}</span>
  <span className='icon flex-2'>
    <FaHandSparkles className='wave-icon ' />
  </span>
  </button>
  <ul className="dropdown-menu ">

  <li><NavLink className="dropdown-item " to={'./Profile'}>Profile</NavLink></li>
  <li><NavLink className="dropdown-item " to={'./MyOrders'}>MyOrders</NavLink></li>
    <li><NavLink className="dropdown-item " onClick={()=>{handleLogout()}}>Sign out</NavLink></li>

  </ul>
</div>
    </div>
    </div>


      </nav>
  )
}
