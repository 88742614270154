import React, { useState } from "react";
import "./ProductCard.css";
import { useDispatch } from "react-redux";
import { AddToCart } from "../../../functions/store/slices/cartSlice";
import { Link } from "react-router-dom";
import placeholderImg  from "../../../assets/imgs/placeholder.jpg"
import { toast } from "react-toastify";

export const ProductCard = (props) => {
  const dispatch = useDispatch();

  const { name, unitePrice, id, category, quantity } = props.product;

  const [ProductRequest, setProductRequest] = useState();

  const [count, setCount] = useState(0);
  const [status, setstatus] = useState('pending');
  const [selectedSize, setSelectedSize] = useState(null);

  const setMaxQuantity = () => {
    if (!selectedSize) {
      setCount(count);
    } else {
      return quantity[`${selectedSize}`];
    }
  };
  const handelCount = (proces, Prevcount, min, max) => {
    if (selectedSize) {




      if (proces === "minus" && Prevcount <= min) {
        setCount(Prevcount);
      } else if (proces === "plus" && Prevcount >= max) {
                setstatus('request')
            toast.info('There are no more items available so your request be created');
            setCount(++Prevcount);
      } else {
        if (proces === "minus") {
          setCount(--Prevcount);
        } else if (proces === "plus") {
          if(max === 0){
            setstatus('request')
            toast.info('There are no more items available so your request be created');
            setCount(++Prevcount);
          }
          setCount(++Prevcount);
        }
      }

    }
 
  };

  const handelSelectionsSize = (e) => {
    setSelectedSize(e);
    setCount(0);
  };

  const handelAddToCart = (e) => {
    if (count > 0) {
      dispatch(
        AddToCart({
          name,
          unitePrice,
          totalPrice: unitePrice * count,
          id,
          type: "item",
          image: props.product.images ? props.product.images[0] : "",
          category,
          status:status,
          size: selectedSize,
          count,
          productcount: setMaxQuantity() - count,
        })
      );
      setSelectedSize(null);
      setCount(0);
    }
  };

  return (
    <div className="accordion card-product my-3" id={id + "p"}>


  

      <div className="accordion-item">
        <div
          className="intialCard"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="true"
          aria-controls={id}
        >
          <div className="image-container">
            <img
              src={props.product.images  ? props.product.images[0] : placeholderImg}
              width={"100%"}
              alt="lol"
              className="product-image"
            />
          </div>
          <div className="card-content px-4 pt-2 d-flex flex-row-reverse">
            <div className="col-9 text-end">
              <h4 className="tag-product">{category}</h4>
              <h4 className="name-product">{name}</h4>
            </div>
            <div className="col-3 align-self-end">
              <h4 className="price-product">
                {" "}
                {unitePrice} <span>EGP</span>
              </h4>
            </div>
          </div>
        </div>
        <div
          id={id}
          className="accordion-collapse collapse collapsed-card "
          data-bs-parent={`#${id + "p"}`}
        >
          <div className="fix"></div>
          <div className="product-card px-2">
            <div className="d-flex align-items-center flex-wrap">
              <div className="col-4">
                <button
                  onClick={() => {
                    handelCount("minus", count, 0, setMaxQuantity()); //handelCount = (proces, Prevcount, min, max)
                  }}
                  className="btn btn-light button-count rounded-circle bg-opacity-25 border border-2"
                >
                  -
                </button>
                <span className="mx-2">{count}</span>
                <button
                  onClick={() => {
                    handelCount("plus", count, 0, setMaxQuantity()); //handelCount = (proces, Prevcount, min, max)
                  }}
                  className="btn btn-light  button-count rounded-circle bg-opacity-25 border border-2"
                >
                  +
                </button>
              </div>
              <div className="col-8">
                <div className="d-flex flex-wrap justify-content-between">
                  {quantity
                    ? Object.keys(quantity)
                        .filter((els) => els !== "none")
                        .map((el, i) => {
                          return (
                            <div
                              key={i}
                              className="form-check m-2 text-start col-3 custom-radio"
                            >
                              <input
                                className={`form-check-input text-start `}
                                type="radio"
                                name={id}
                                id={id + i}
                                value={el}
                                
                                onChange={(e) => {
                                  handelSelectionsSize(e.target.value);
                                }}
                              />
                              <label className="form-check-label  " htmlFor={id + i}>
                                <span className="mt-2 d-block">{el}</span>
                              </label>
                            </div>
                          );
                        })
                    : ""}
                </div>
              </div>
              
            </div>
            <p className=" mt-3 text-left">
              {quantity[`${selectedSize}`] === 0?<>
              
              this
              <span className="text-danger mx-2">

                {selectedSize}
              </span>
               
               
                size of
               <span className="text-danger mx-2">
               {name}
               </span>
                 is not available now,add to cart and will be notified you when be ready
              </>
            
              :
              <span className="py-2">
                  ...
              </span>
              
              
            }
              
              </p>
            <div className="d-flex justify-content-between align-items-center">
              <Link
                to={"/Items/" + id}
                className="btn btn-outline-info text-dark   w-25 mt-2  mb-3"
              >
                More
              </Link>
              <button
                className="btn btn-success w-50 mt-2 mb-3 "
                onClick={(e) => {
                  handelAddToCart(e);
                }}
              >

                Add To Cart
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
